<template>
    <main style="background: white; height: 100vh">
        <BackHeader>
            <template v-slot:center>
                <img :src="_logo" alt="Dosi-in">
            </template>
        </BackHeader>
        <h1 class="login-title">Khôi phục mật khẩu</h1>
        <ResetPassword />
    </main>
</template>

<script>
    import ResetPassword from '@/components/forms/ResetPassword';
    import BackHeader from '@/components/common/BackHeader';
    export default {
        components:{
            ResetPassword,
            BackHeader
        },
        setup() {
            
        },
    }
</script>

<style>
    .phone_world{
        height: 54px;
        background: #fff;
        box-sizing: border-box;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 8px 0 0;
        border-color: #e6e6e6;
        border-width: 1px;
        border-style: solid;
        border-radius: 8px;
        padding: 12px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        font-family: 'sf-pro-display';
    }
    .dosiin_pd-16{
        padding: 16px;
    }
</style>