<template>
    <form @submit.prevent="handleResetPassword">
        <div class="dosiin_pd-16">
            <template v-if="step == 1">
                <vue-tel-input 
                    v-model="resetPasswordUser.phone" 
                    :onInput="onInputPhone"
                    :dropdownOptions="{
                        showFlags : true,
                        showDialCodeInSelection: true,
                    }"

                    :inputOptions="{
                        placeholder : 'Nhập số điện thoại của bạn'
                    }"
                >
                </vue-tel-input>

                <div id="recaptcha-container"></div>
            </template>

            <template v-if="step == 2">
                <VueOtp2 
                    ref="otpInput"
                    input-classes="otp-input"
                    separator=""
                    :num-inputs="6"
                    :should-auto-focus="true"
                    :is-input-num="true"
                    @on-failed="backToStep"
                    @on-complete="verifyOtp"
                    :phone="phoneObj.number"
                    :timer="40"
                    v-model="loading"
                />
            </template>

            <template v-if="step == 3">
                <h4 class="ttl-reser">Thay đổi mật khẩu </h4>

                <div class="form-group">
                    <input 
                        class="form-control" 
                        type="password"
                        ref="password"
                        v-model.trim="resetPasswordUser.password1" 
                        v-password
                        required
                    />
                    <label for="name">Mật khẩu mới</label>
                    <i class="delete">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66667 10.5003C1.66667 5.90553 5.40521 2.16699 10 2.16699C14.5948 2.16699 18.3333 5.90553 18.3333 10.5003C18.3333 15.0951 14.5948 18.8337 10 18.8337C5.40521 18.8337 1.66667 15.0951 1.66667 10.5003ZM10 2.79922C5.75331 2.79922 2.29889 6.25363 2.29889 10.5003C2.29889 14.747 5.75331 18.2014 10 18.2014C14.2467 18.2014 17.7011 14.747 17.7011 10.5003C17.7011 6.25363 14.2467 2.79922 10 2.79922ZM7.84099 8.34131C7.96499 8.21731 8.16321 8.21731 8.28721 8.34131L10 10.0541L11.7128 8.34131C11.8368 8.21731 12.035 8.21731 12.159 8.34131C12.283 8.46531 12.283 8.66353 12.159 8.78753L10.4461 10.5005L12.166 12.2133C12.2899 12.3373 12.2899 12.5354 12.1659 12.6593C12.1038 12.7215 12.0242 12.7523 11.9411 12.7523C11.8579 12.7523 11.7784 12.7215 11.7162 12.6593L10.0034 10.9465L8.29065 12.6593C8.22852 12.7215 8.14895 12.7523 8.06582 12.7523C7.98269 12.7523 7.90313 12.7215 7.84099 12.6593C7.71699 12.5353 7.71699 12.3371 7.84099 12.2131L9.55379 10.5003L7.84099 8.78753C7.71699 8.66353 7.71699 8.46531 7.84099 8.34131Z" fill="#222222"></path>
                        </svg>
                    </i>
                    <span class="show-pass"><span class="text-pink-gradient">Hiện</span></span>
                </div>

                <div class="form-group">
                    <input 
                        class="form-control" 
                        type="password"
                        ref="confirmPassword"
                        v-model.trim="resetPasswordUser.password2" 
                        v-password
                        required
                    />

                    <label for="name">Nhập lại mật khẩu</label>
                    <i class="delete">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66667 10.5003C1.66667 5.90553 5.40521 2.16699 10 2.16699C14.5948 2.16699 18.3333 5.90553 18.3333 10.5003C18.3333 15.0951 14.5948 18.8337 10 18.8337C5.40521 18.8337 1.66667 15.0951 1.66667 10.5003ZM10 2.79922C5.75331 2.79922 2.29889 6.25363 2.29889 10.5003C2.29889 14.747 5.75331 18.2014 10 18.2014C14.2467 18.2014 17.7011 14.747 17.7011 10.5003C17.7011 6.25363 14.2467 2.79922 10 2.79922ZM7.84099 8.34131C7.96499 8.21731 8.16321 8.21731 8.28721 8.34131L10 10.0541L11.7128 8.34131C11.8368 8.21731 12.035 8.21731 12.159 8.34131C12.283 8.46531 12.283 8.66353 12.159 8.78753L10.4461 10.5005L12.166 12.2133C12.2899 12.3373 12.2899 12.5354 12.1659 12.6593C12.1038 12.7215 12.0242 12.7523 11.9411 12.7523C11.8579 12.7523 11.7784 12.7215 11.7162 12.6593L10.0034 10.9465L8.29065 12.6593C8.22852 12.7215 8.14895 12.7523 8.06582 12.7523C7.98269 12.7523 7.90313 12.7215 7.84099 12.6593C7.71699 12.5353 7.71699 12.3371 7.84099 12.2131L9.55379 10.5003L7.84099 8.78753C7.71699 8.66353 7.71699 8.46531 7.84099 8.34131Z" fill="#222222"></path>
                        </svg>
                    </i>
                    <span class="show-pass"><span class="text-pink-gradient">Hiện</span></span>
                </div>
                
            </template>

            <button class="primary-button button btn-login" type="submit"> 
                <span v-if="!loading" class="primary-link_text" v-text="step == 3  ? 'Đặt lại mật khẩu' : 'Tiếp tục'"></span>
                <pulse-loader v-else :loading="loading"></pulse-loader>
            </button>
        </div>
    </form>
</template>

<script>
    import { reactive, ref , onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import AuthService from '@/services/auth';
    import * as validator from '@/utlis/validation';
    import { useToast } from "vue-toastification";
    import VueTelInput   from '@/components/forms/TelInput';
    import VueOtp2 from "@/components/forms/OTP.vue";
    import { getAuth , RecaptchaVerifier , signInWithPhoneNumber } from "firebase/auth";
    import ClickToRegister from '@/components/toasts/ClickToRegister';
    export default {
        components:{
            VueOtp2,
            VueTelInput
        },

        setup(){
            const toast = useToast();
            const store = useStore();
            const router = useRouter();
            const resetPasswordUser = reactive({
                user_id : 0,
                phone   : '',
                password1 : '',
                password2: '',
                user    : null,
            });

            const validation = reactive({
                password    : true,
                confirmPassword : true,
            });
            
            const password1  = ref(null);
            const password2 = ref(null);
            const loading   = ref(false);
            const step      = ref(1);
            const phoneObj  = ref({});
            const authFirebase = getAuth();

            onMounted(() => {
                window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                        onSignInSubmit();
                    }
                }, authFirebase);
            });
            
            async function handleResetPassword(){
                // validation 
                if(step.value == 1 ){
                    validation.phone     = validator._isVietnamesePhoneNumber(resetPasswordUser.phone.replace(/ +/g,''));
                    if(!resetPasswordUser.phone || !validation.phone){
                        toast.error('Số điện thoại không đúng định dạng');
                        return;
                    }
                    loading.value = true;
                    // check phone exits 
                    const res = await AuthService.checkUserExistWithPhone(resetPasswordUser.phone.replace(/ +/g,''));

                    if(res.data && (res.data.userExist || res.data.user_id)){
                        sendOtp();
                    }else{
                        loading.value = false;
                        toast.error({component : ClickToRegister});
                    }

                }else if(step.value == 2){

                }else if(step.value == 3){

                    validation.password     = validator._minLength(resetPasswordUser.password1,6);
                    validation.confirmPassword  = validator._matchPassword(resetPasswordUser.password1 , resetPasswordUser.password2);

                    if(!resetPasswordUser.password1 || !validation.password){
                        password1.value.focus();
                        toast.error('Mật khẩu phải có ít nhất 6 ký tự');
                        return;
                    }

                    if(!resetPasswordUser.password2 || !validation.confirmPassword){
                        password2.value.focus();
                        toast.error('Mật khẩu xác nhận không đúng');
                        return;
                    }


                    loading.value = true;
                    const response = await AuthService.update(resetPasswordUser);
                    
                    if(response && response.data){
                        if(response.data.message){
                            toast.success(response.data.message);
                        }
                        router.push({name : 'login'});
                    }else{
                        loading.value = false;
                    }
                }
            }

            async function sendOtp(){
                let phoneNumber = phoneObj.value.number;
                const appVerifier = window.recaptchaVerifier;
                await signInWithPhoneNumber(authFirebase , phoneNumber, appVerifier)
                    .then(function (confirmationResult) {
                        // SMS sent. Prompt user to type the code from the message, then sign the
                        // user in with confirmationResult.confirm(code).
                        window.confirmationResult = confirmationResult;
                        toast.success('Vui lòng nhập mã xác thực đã được gửi tới số điện thoại của bạn');
                        step.value = 2;
                        loading.value = false;
                    }).catch(function (error) {
                        toast.error('Lỗi , không thể gửi tin nhắn đến số điện thoại này');
                        loading.value = false;
                    });
            }

            async function verifyOtp(value){
                window.confirmationResult.confirm(value).then(async function (result) {
                    // User signed in successfully.
                    let userOTP = result.user;
                    if(userOTP){
                        resetPasswordUser.user = userOTP;
                        const resAuth =  await AuthService.getUserIdByPhone(resetPasswordUser.phone.replace(/ +/g,''));
                        resetPasswordUser.user_id = resAuth.data.user_id;
                        step.value = 3;
                    }
                }).catch(function (error) {
                    loading.value = false;
                    toast.warning('Mã xác thực không đúng vui lòng kiểm tra lại');
                    // User couldn't sign in (bad verification code?)
                });
            }

            function onInputPhone(phoneOrigin, phoneObject, input) {
                if (phoneObject?.formatted) {
                    resetPasswordUser.phone = phoneObject.formatted;
                    phoneObj.value = phoneObject;
                }
            }

            const backToStep = (value) => {
                step.value = value;

                window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                        onSignInSubmit();
                    }
                }, authFirebase);
            }

            return {
                resetPasswordUser,  
                loading,
                step,   
                phoneObj,           
                // refs 
                password1,
                password2,
                // methods
                onInputPhone,
                handleResetPassword,
                validation,
                verifyOtp,
                backToStep
            }
        },
    }
</script>