<template>
    <ResetPasswordMB v-if="layout == 4"/>
    <ResetPasswordPC v-else/>
</template>

<script>
    import ResetPasswordMB from '@/pages/mb/auth/ResetPassword';
    import ResetPasswordPC from '@/pages/pc/auth/ResetPassword';
    export default {
        components:{
            ResetPasswordMB,
            ResetPasswordPC
        },
    }
</script>